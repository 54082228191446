$appBackgroundColor: #fff;
$mobileBackgroundColor: #f2f2f2;

$textXS: 0.75rem;
$textSM: 0.875rem;
$textBase: 1rem;
$textLG: 1.5rem;
$textXL: 2.25rem;
$textXXL: 3rem;

$textBaseWeight: 400;
$textBoldWeight: 700;

$spaceBaseUnit: 1rem; // assume 16px
$spaceXXS: calc(0.25 * #{$spaceBaseUnit}); // 4px
$spaceXS: calc(0.5 * #{$spaceBaseUnit}); // 8px
$spaceSM: calc(0.75 * #{$spaceBaseUnit}); // 12px
$spaceMD: calc(1.25 * #{$spaceBaseUnit}); // 20px
$spaceLG: calc(2 * #{$spaceBaseUnit}); // 32px
$spaceXL: calc(3.25 * #{$spaceBaseUnit}); // 52px
$spaceXXL: calc(5.25 * #{$spaceBaseUnit}); // 84px

$colorPrimary: #1688fe;
$colorSecondary: #ea526f;

$colorMediumGray: #777777;
$colorLightGray: #cccccc;

$colorText: #424242;
$colorLightText: #9d9d9d;

$colorIcon: $colorMediumGray;

$colorBorder: $colorLightGray;
$colorButtons: $colorMediumGray;

$colorLightBackground: #f2f2f2;

$colorSuccess: #7BD130;
$colorInfo: #36BCED;
$colorWarning: #FCBB41;
$colorError: #FF6030;

$borderRadiusFrames: 8px;
$borderRadiusControls: 8px;

@mixin flex-column() {
  display: flex;
  flex-direction: column;
}

@mixin flex-row() {
  display: flex;
  flex-direction: row;
}

:export {
  colorText: $colorText;
  colorPrimary: $colorPrimary;
  colorIcon: $colorIcon;
  spaceXXS: $spaceXXS;
  spaceXS: $spaceXS;
  borderRadiusControls: $borderRadiusControls;
}
